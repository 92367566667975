import React, { useState, useEffect } from 'react'
import api from '../api'

import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    InboxOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { Typography, Tag, message, Modal, Input, Button, Alert, Table, Tooltip, Drawer, Descriptions, Upload } from 'antd'

import { ProCard } from '@ant-design/pro-components'
const { Text, Title } = Typography
const { TextArea } = Input
const { Dragger } = Upload



//  控制台-一键去墙
function AwsV2(props) {
    const [data, setData] = useState([])
    const [htmlData, setHtmlData] = useState({ Data: [] })
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [status, setStatus] = useState(false)
    const [update, setUpdate] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.get('/api/v2/get_aws_task/v2')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    setLoading(false)
                    setData(Data)
                    // getStatus()
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [update])


    //  表格
    const columns = [
        {
            title: '任务ID',
            dataIndex: '_id',
            key: '_id',
        },
        {
            title: '资源名称',
            dataIndex: 'resource_name',
            key: 'resource_name',
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            render: (state, record) => {
                if (state === 1) {
                    return (
                        <Tag icon={<SyncOutlined spin />} color="processing" >正在处理中</Tag>
                    )
                }
                if (state === 2) {
                    return (
                        <Tag icon={<CheckCircleOutlined />} color="success" >已完成</Tag>
                    )
                }

                return (
                    <Tag icon={<ExclamationCircleOutlined />} color="warning" >排队处理中</Tag>
                )
            },
        },
        {
            title: '创建时间',
            dataIndex: 'ctime',
            key: 'ctime',
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Tag color="processing" className='clogs-check' onClick={() => {
                    // message.success('ok:' + record._id)
                    if (record.state === 2) {
                        download(record.dw_url)
                        return
                    }
                    message.error('当前任务似乎未完成')
                }}>下载文件</Tag>
            ),
        },
    ]



    // //  获取节点通信
    // function getStatus() {
    //     api.get('/api/v2/get_status', {
    //         params: {
    //             name: 'aws'
    //         }
    //     })
    //         .then((res) => {
    //             if (res.data.code === 200) {
    //                 setStatus(false)
    //             } else {
    //                 setStatus(true)
    //             }
    //         })
    //         .catch((error) => {
    //             message.error('网络异常,无法连接服务器')
    //         })
    // }

    function onChange(e) {
        setHtmlData({
            ...htmlData,
            [e.target.name]: e.target.value,
        })
    }


    /**
 * 下载功能处理
 */
    function download(url) {
        let Data = url
        const link = document.createElement('a')
        link.href = Data
        link.click()
        setLoading(false)
        message.success('已经开始下载了')
    }

    //  开始处理
    function start() {
        if (!htmlData.html) {
            return message.warning('请粘贴HTML代码再处理!')
        }
        if (!htmlData.resource_name) {
            return message.warning('请填写资源名称再处理!')
        }
        if (!htmlData.fxmanifest) {
            return message.warning('请粘贴 fxmanifest.lua 代码再处理!')
        }

        api.post('/api/v2/aws/v2', {
            data: JSON.stringify({
                value: htmlData.html,
                html_name: htmlData.html_name,
                fxmanifestData: htmlData.fxmanifest,
                resource_name: htmlData.resource_name,
            })
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setModal(false)
                    message.success('任务已提交,请稍等后台处理...')
                    setUpdate(Date.now())
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    //  添加任务
    function addTask() {
        setModal(true)
    }

    const Refresh = () => {
        setUpdate(Date.now())
    }

    //  读取内容
    const readFileContent = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.onerror = (e) => reject(e);
            reader.readAsText(file);
        });
    };

    //  文件上传
    const uploadProps = {
        name: 'file',
        maxCount: 1,
        async customRequest(info) {
            if (info.file.size > 1048576) {
                message.error('文件大小超过1MB, 无法上传')
                info.onError()
                return
            }

            if (info.file.type !== 'text/html') {
                message.error('文件只能是html格式的, 无法上传')
                info.onError()
                return
            }


            setHtmlData({
                ...htmlData,
                html: await readFileContent(info.file),
                html_name: info.file.name,
            })
        },
    }
    //  文件上传
    const uploadPropsfx = {
        name: 'file',
        maxCount: 1,
        async customRequest(info) {
            if (info.file.size > 1048576) {
                message.error('文件大小超过1MB, 无法上传')
                info.onError()
                return
            }

            if (info.file.name !== 'fxmanifest.lua') {
                message.error('文件只能是fxmanifest.lua文件的, 无法上传')
                info.onError()
                return
            }

            setHtmlData({
                ...htmlData,
                fxmanifest: await readFileContent(info.file),
            })
        },
    }


    function Upload_Div() {
        return (
            <Dragger {...uploadProps} style={{ marginTop: 10 }}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">点击或拖拽 html 文件到此区域上传</p>
                <p className="ant-upload-hint">
                    仅支持大小为1MB以内的单个 html 文件上传进行读取。
                </p>
            </Dragger>
        )
    }

    function Upload_fxDiv() {
        return (
            <Dragger {...uploadPropsfx} style={{ marginTop: 10 }}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">点击或拖拽 fxmanifest.lua 文件到此区域上传</p>
                <p className="ant-upload-hint">
                    仅支持大小为1MB以内的单个 fxmanifest.lua 文件上传进行读取。
                </p>
            </Dragger>
        )
    }



    return (
        <div>
            {/* <Alert
                message="远程缓存服务离线"
                description="系统检查到此服务已离线或异常,这可能会影响部分功能,请稍后再次刷新查看服务状态,如果多次仍然还是一样,请联系站务进行处理"
                type="error"
                showIcon
                style={{ marginBottom: 20, display: status ? '' : 'none' }}
            /> */}

            {/* <Alert
                message="业务调整通知"
                description="现已调整为分级使用, 例如: NV1 和 NV2 级别的会员, 各享受不同的权益, 近期将会持续调整本网站的会员机制, 请关注Q群群公告或网站公告。"
                type="warning"
                showIcon
                style={{ marginBottom: 20 }}
            /> */}

            <Alert
                message="使用说明"
                description={<div>目前还不支持图标库的本地化, 处于测试阶段, 每个用户每天可以提交不大于10个任务!<br />视频教程: <Typography.Link href="https://www.bilibili.com/video/BV1rLdUYpERB" target='_blank' >https://www.bilibili.com/video/BV1rLdUYpERB</Typography.Link></div>}
                type="warning"
                showIcon
                style={{ marginBottom: 20 }}
            />



            <Alert message="任务队列有效期默认为: 24 小时,请及时刷新观看任务的状态 [免责声明: 去墙系统不承诺可以100%去墙, 可能存在部分无法支持去墙情况]" type="info" />

            <ProCard style={{ width: '100%', marginTop: 20 }} bordered>
                <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} > 一键去墙V2 <Tag color="processing">限免</Tag></Title>
                <Button onClick={addTask} style={{ float: 'right', marginBottom: 20, marginRight: 10 }}>添加任务</Button>
                <Button onClick={Refresh} type="primary" style={{ float: 'right', marginBottom: 20, marginRight: 10 }} icon={<SyncOutlined />} />
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    style={{ marginTop: 20 }}
                />
            </ProCard>

            <Modal
                title="添加任务"
                centered
                open={modal}
                onOk={start}
                onCancel={() => setModal(false)}
                keyboard={false}
                maskClosable={false}
                width={1000}
            >
                <Input placeholder="资源名称" addonBefore='资源名称 (区分大小写)' style={{ width: 400 }} name='resource_name' value={htmlData.resource_name} onChange={onChange} />

                {htmlData.fxmanifest ? <TextArea rows={10} placeholder="请粘贴 fxmanifest.lua 代码..." style={{ marginTop: 10, width: '100%' }} value={htmlData.fxmanifest} name='fxmanifest' onChange={onChange} /> : <Upload_fxDiv />}

                {htmlData.html ? <TextArea rows={10} placeholder="请粘贴HTML代码..." style={{ marginTop: 10, width: '100%' }} value={htmlData.html} name='html' onChange={onChange} /> : <Upload_Div />}
            </Modal>
        </div >
    )
}

export default AwsV2;